<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.248"
    height="16.119"
    viewBox="0 0 28.248 16.119"
  >
    <path
      data-name="Pfad 270"
      d="M150.629,81.06c-8.1,0-13.009-6.158-13.91-7.393a1.125,1.125,0,0,1,0-1.334c.9-1.234,5.809-7.392,13.91-7.392,7.454,0,12.855,6.126,13.863,7.356h0a1.131,1.131,0,0,1,.038,1.385C163.627,74.914,158.711,81.06,150.629,81.06ZM138.093,73c1.151,1.5,5.553,6.56,12.536,6.56,6.945,0,11.348-5.019,12.517-6.534-1.29-1.509-6.1-6.585-12.517-6.585C143.646,66.441,139.244,71.5,138.093,73Zm25.238.247,0,0h0Z"
      transform="translate(-136.5 -64.941)"
      :class="classes"
    />
    <path
      data-name="Pfad 271"
      d="M150.586,76.711a3.574,3.574,0,1,1,3.574-3.574A3.578,3.578,0,0,1,150.586,76.711Zm0-5.648a2.074,2.074,0,1,0,2.074,2.074A2.077,2.077,0,0,0,150.586,71.063Z"
      transform="translate(-136.5 -64.941)"
      :class="classes"
    />
  </svg>
</template>
<script>
export default {
  name: "SeeSvg",
  props: ["classes"],
  components: {},
};
</script>
