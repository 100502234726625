<template>
  <div class="navbar--bottom main__padding" v-if="showNavbar">
    <div class="navbar--routes">
      <div class="navbar--route">
        <router-link :to="{ name: 'Home' }">
          <SearchSvg />
          <div>Suche</div>
        </router-link>
      </div>
      <div class="navbar--route" v-if="token">
        <router-link :to="{ name: 'Calendar' }">
          <CalenderSvg />
          <div>Meine Termine</div>
        </router-link>
      </div>
      <div class="navbar--route" v-if="token">
        <router-link :to="{ name: 'Settings' }">
          <SettingSvg />
          <div>Einstellungen</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Navbar",
  data() {
    return {
      // !TODO
      showNavbar: false,
      links: [],
    };
  },
  methods: {
    ...mapActions([]),
  },
  created() {},
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {},
};
</script>
