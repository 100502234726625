import Axios from "axios";
import router from "../../router";

const state = {
    chat: [],
};

const getters = {
    myChat: (state) => state.chat,
};
const mutations = {
    setMyChat: (state, value) => {
        state.chat = value;
    },
    addMessageToChat: (state, message) => {
        state.chat.chatMessages.push(message);
    },
};
const actions = {
    async receiveChat({ commit, dispatch }, appointmentId) {
        await Axios.get(window.baselink + "chat/" + appointmentId + "/receive")
            .then(function (response) {
                if (response.status == 200) {
                    commit("setMyChat", response.data.chat);

                    dispatch("sendMessage", {
                        id: appointmentId,
                        message: "Interessent*in ist dem Chat beigetreten",
                        status: "system",
                    });
                }
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    window.localStorage.removeItem("token");
                    dispatch("setToken", null);
                    return router.push("/login");
                }
            });
    },
    async sendMessage({ commit, dispatch }, { id, message, status }) {
        return await Axios.post(window.baselink + "chat/" + id + "/send", {
            message,
            status,
        })
            .then(function (response) {
                if (response.status == 200) {
                    commit("addMessageToChat", response.data.newMessage);
                    return true;
                }
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    window.localStorage.removeItem("token");
                    dispatch("setToken", null);
                    return router.push("/login");
                }
                return false;
            });
    },
};

export default {
    namespace: "chat",
    state,
    getters,
    actions,
    mutations,
};
