<template>
  <div
    class="company_heading row__spacebetween row__spacebetween--bottom mb-25"
  >
    <img :src="oneCompany.logo" />
    <div class="backlinks">
      <a
        target="_blank"
        v-if="oneCompany.linkedInLink"
        :href="oneCompany.linkedInLink"
        class="backlinks--link"
      >
        <LinkedInSvg />
      </a>
      <a
        target="_blank"
        v-if="oneCompany.xingLink"
        :href="oneCompany.xingLink"
        class="backlinks--link"
      >
        <XingSvg />
      </a>
      <a
        target="_blank"
        v-if="oneCompany.instagramLink"
        :href="oneCompany.instagramLink"
        class="backlinks--link"
      >
        <InstagramSvg />
      </a>
      <a
        target="_blank"
        v-if="oneCompany.facebookLink"
        :href="oneCompany.facebookLink"
        class="backlinks--link"
      >
        <FacebookSvg />
      </a>
      <a
        target="_blank"
        v-if="oneCompany.youtubeLink"
        :href="oneCompany.youtubeLink"
        class="backlinks--link"
      >
        <YoutubeSvg />
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CompanyHeader",
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
  },
  created() {},
  computed: {
    ...mapGetters(["oneCompany"]),
  },
  mounted() {},
};
</script>
