<template>
	<div>
		<div class="searchbox row__start">
			<input
				type="text"
				v-model="companyName"
				placeholder="Suche nach Name des Unternehmens"
				@keyup="
					getAvailableCompanies({
						occupationId: selectedOccupation.id,
						offerId: selectedOffer.id,
						filterText: companyName,
					})
				"
			/>
			<SearchSvg />
		</div>
		<p class="text__primary m-15">oder</p>
		<div class="searchbox row__start searchbox--select">
			<input
				type="text"
				readonly
				v-model="selectedOccupation.name"
				placeholder="Suche über Berufsfelder"
			/>
		</div>
		<SelectBox
			classes="selectbox--big"
			area="occupation"
			:options="occupationOptions"
			:selectedId="selectedOccupation.id"
			@click="setSelectOption"
		/>
		<div class="searchbox row__start searchbox--select mt-15">
			<input
				type="text"
				readonly
				v-model="selectedOffer.name"
				placeholder="Angebot auswählen"
			/>
		</div>
		<SelectBox
			:options="offerOptions"
			area="offer"
			:selectedId="selectedOffer.id"
			@click="setSelectOption"
		/>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";

	export default {
		name: "HomeFilterCompanies",
		data() {
			return {
				selectedOccupation: { id: null, name: "" },
				selectedOffer: { id: null, name: "" },
				companyName: "",
			};
		},
		methods: {
			...mapActions(["getAvailableCompanies"]),
			setSelectOption(option) {
				let reload = false;
				if (option.area == "offer") {
					if (this.selectedOffer.id != option.id) {
						reload = true;
					}
					if (option.id == null) {
						this.selectedOffer = { id: null, name: "" };
					} else {
						this.selectedOffer = option;
					}
				} else if (option.area == "occupation") {
					if (this.selectedOccupation.id != option.id) {
						reload = true;
					}
					if (option.id == null) {
						this.selectedOccupation = { id: null, name: "" };
					} else {
						this.selectedOccupation = option;
					}
				}
				if (reload) {
					this.getAvailableCompanies({
						occupationId: this.selectedOccupation.id,
						offerId: this.selectedOffer.id,
						filterText: this.companyName,
					});
				}
			},
		},
		watch: {
			selectedOccupation(selOcc) {
				sessionStorage.occupationId = selOcc.id;
				sessionStorage.occupationName = selOcc.name;
			},
			selectedOffer(selOff) {
				sessionStorage.offerId = selOff.id;
				sessionStorage.offerName = selOff.name;
			},
		},
		created() {
			if (sessionStorage.occupationId) {
				this.selectedOccupation.id = sessionStorage.occupationId;
				this.selectedOccupation.name = sessionStorage.occupationName;
			}
			if (sessionStorage.offerId) {
				this.selectedOffer.id = sessionStorage.offerId;
				this.selectedOffer.name = sessionStorage.offerName;
			}
			this.getAvailableCompanies({
				occupationId: this.selectedOccupation.id,
				offerId: this.selectedOffer.id,
				filterText: this.companyName,
			});
		},
		computed: {
			...mapGetters(["offerOptions", "occupationOptions"]),
		},
	};
</script>
