<template>
    <div
        class="row__start userCheckBox"
        @click="makeClickEvent"
        :class="classes"
    >
        <div
            class="userCheckBox--trigger"
            :class="active ? 'userCheckBox__trigger--active' : ''"
        ></div>
        <div class="userCheckBox--image">
            <img :src="user.image" v-if="user.image" />
            <LaughingUserSvg v-else />
        </div>
        <div class="column__spacebetween column__spacebetween--start">
            <p>{{ user.name }}</p>
            <p>{{ user.jobTitle }}</p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "UserCheckBox",
    props: ["classes", "active", "user"],
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
        makeClickEvent() {
            this.$emit("click", this.user);
        },
    },
    created() {},
    computed: {
        ...mapGetters([]),
    },
    mounted() {},
};
</script>
