<template>
	<div v-if="oneCompany && selectedCompanyImage" class="h100 w-100">
		<div
			v-if="
				oneCompany.images.length == 1 &&
					((selectedCompanyImage.fileType == 'video' &&
						youtube_parser(selectedCompanyImage.link)) ||
						selectedCompanyImage.fileType != 'video' ||
						(selectedCompanyImage.fileType == 'video' &&
							is_vimeo(selectedCompanyImage.link)))
			"
			class="h100"
		>
			<div class="image__box image__box--l">
				<youtube
					v-if="
						selectedCompanyImage.fileType == 'video' &&
							!is_vimeo(selectedCompanyImage.link)
					"
					:video-id="youtube_parser(selectedCompanyImage.link)"
					style="max-height:40vh;"
				></youtube>
				<vimeo-player
					v-if="
						selectedCompanyImage.fileType == 'video' &&
							is_vimeo(selectedCompanyImage.link)
					"
					:video-id="get_vimeo_id(selectedCompanyImage.link)"
					style="max-height:40vh;"
				></vimeo-player>
				<img
					:src="selectedCompanyImage.link"
					v-if="selectedCompanyImage.fileType != 'video'"
					style="max-height:40vh;"
				/>
			</div>
		</div>
		<div
			v-if="oneCompany.images.length > 1"
			class="row__start row__start--stretch h100 image__scrollBox"
		>
			<div
				class="row__start--flex1 column__start scrollbar"
				style="max-width: 25%; height: 10vh"
			>
				<div
					v-for="image in oneCompany.images"
					:key="image.id"
					class="image__container pointer"
					:class="
						image.id == selectedCompanyImage.id
							? 'image__container--active'
							: ''
					"
					@click.stop="changeCompanyImage(image)"
					v-show="
						(image.fileType == 'video' &&
							youtube_parser(image.link)) ||
							image.fileType != 'video' ||
							(image.fileType == 'video' && is_vimeo(image.link))
					"
				>
					<youtube
						v-if="
							image.fileType == 'video' &&
								youtube_parser(image.link) &&
								!is_vimeo(image.link)
						"
						:video-id="youtube_parser(image.link)"
					>
					</youtube>
					<vimeo-player
						v-if="image.fileType == 'video' && is_vimeo(image.link)"
						:video-id="get_vimeo_id(image.link)"
						style="height: 100px"
					></vimeo-player>
					<div
						class="absolute--overlay"
						v-if="image.fileType == 'video'"
						@click.stop="changeCompanyImage(image)"
					></div>

					<img v-if="image.fileType != 'video'" :src="image.link" />
				</div>
			</div>
			<div class="row__start--flex3">
				<div
					class="image__box image__box--l"
					:class="
						selectedCompanyImage.fileType == 'video' &&
						!is_vimeo(selectedCompanyImage.link)
							? ''
							: 'image__box--hide'
					"
				>
					<youtube
						:video-id="youtube_parser(selectedCompanyImage.link)"
					></youtube>
				</div>
				<div
					class="image__box image__box--l"
					:class="
						selectedCompanyImage.fileType == 'video' &&
						is_vimeo(selectedCompanyImage.link)
							? ''
							: 'image__box--hide'
					"
				>
					<vimeo-player
						style="height: 100%"
						:video-id="get_vimeo_id(selectedCompanyImage.link)"
						v-if="
							selectedCompanyImage.fileType == 'video' &&
								is_vimeo(selectedCompanyImage.link)
						"
					></vimeo-player>
				</div>
				<div
					:class="
						selectedCompanyImage.fileType != 'video'
							? ''
							: 'image__box--hide'
					"
					class="image__box image__box--l"
				>
					<img
						:src="selectedCompanyImage.link"
						v-if="selectedCompanyImage.fileType != 'video'"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";

	export default {
		name: "CompanyImages",
		data() {
			return {};
		},
		methods: {
			...mapActions([]),
			youtube_parser(url) {
				var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
				var match = url.match(regExp);
				return match && match[7].length == 11 ? match[7] : false;
			},
			is_vimeo(url) {
				return url.includes("vimeo");
			},
			get_vimeo_id(url) {
				return /[^/]*$/.exec(url)[0];
			},
			changeCompanyImage(image) {
				console.log("Change company");
				this.$store.commit("setSelectedCompanyImage", image);
			},
		},
		created() {},
		computed: {
			...mapGetters(["oneCompany", "selectedCompanyImage"]),
		},
		mounted() {},
	};
</script>
