import Axios from "axios";
import router from "../../router";

const state = {
    dummy: "yeet",
};

const getters = {};

const mutations = {};

const actions = {
    requestResetLink({ state }, userMail) {
        state.dummy = "nicht mehr yeet";
        return Axios.post(window.baselink + "forgotPassword", {
            email: userMail,
        })
            .then((response) => {
                if (response.status == 200) {
                    return response.data;
                }
            })
            .catch((error) => {
                // return error.status;
                return error.response.data;
            });
    },
    resetPassword(
        { state },
        { email, password, password_confirmation, token }
    ) {
        state.dummy = "nicht mehr yeet";
        return Axios.post(window.baselink + "resetPassword", {
            email,
            password,
            password_confirmation,
            token,
        })
            .then((response) => {
                if (response.status == 200) {
                    router.push({
                        name: "Login",
                    });
                }
            })
            .catch((error) => {
                if (error.response.status == 444)
                    return error.response.data.msg;
            });
    },
};

export default {
    namespace: "password",
    state,
    getters,
    actions,
    mutations,
};
