import Vue from "vue";
import VueRouter from "vue-router";
// Company
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Register.vue"),
    },
    {
        path: "/password",
        name: "Password",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Password.vue"),
    },
    {
        path: "/password/reset",
        name: "Reset",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Reset.vue"),
    },
    {
        path: "/calendar",
        name: "Calendar",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Calendar.vue"),
    },
    {
        path: "/settings",
        name: "Settings",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
    },
    {
        path: "/company/:id",
        name: "Company",
        props: true,
        component: () => import("../views/Company.vue"),
    },
    {
        path: "/chat/:id",
        name: "Chat",
        props: true,
        component: () => import("../views/Chat.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
