<template>
    <div class="hamburger-icon">
        <label class="hamburg" :class="{ 'icon--active': active }">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
        </label>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "burgerMenu",
    props: ["active"],
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {
        ...mapGetters([]),
    },
    mounted() {},
};
</script>
