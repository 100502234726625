<template>
  <div class="row__spacebetween row__spacebetween--wrap mb-60">
    <div
      class="company__result--box"
      v-for="result in availableCompanies"
      :key="result.id"
      @click.stop="chooseResult(result)"
    >
      <div class="company__box--logo">
        <img :src="result.image" />
      </div>
      <p :class="selectedCompany.id == result.id ? 'text__primary' : ''">
        {{ result.name }}
      </p>
      <div
        class="company__box--arrow"
        :class="selectedCompany.id == result.id ? '' : 'size0'"
      >
        <ArrowRightActiveSvg />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HomeCompanyResults",
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
    chooseResult(result) {
      if (this.selectedCompany.id == result.id) {
        this.$router.push({
          name: "Company",
          params: { id: result.id },
        });
      } else {
        this.availableCompanies.forEach((company) => {
          company.showLabel = false;
        });
        result.showLabel = true;
        this.$store.commit("setSelectedCompany", result);
        this.$store.commit("setMapsCenterPoint", result.allLatLongs[0].latLong);
      }
    },
  },
  created() {},
  computed: {
    ...mapGetters(["availableCompanies", "selectedCompany"]),
  },
};
</script>
